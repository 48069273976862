html {
    position: relative;
    display: flex;
    flex-direction: column;
}

body {
    background: #EDE9E7;
    font-family: Ubuntu, sans-serif;
    font-weight: 300;
    border-radius: 0;
    color: #42211b;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    min-width: 320px;
}

#main-flex {
    flex: 1 0 auto;
}

#main-container {
    margin-top: 91px;
}

#homepage-intro-row {
    margin-top: -20px;
}

th {
    word-break: normal;
}

h1, h2, h3, h4, h5 {
    font-family: Ubuntu, sans-serif;
    font-weight: 400;
}

a {
    color: #3E2622;
    transition-timing-function: ease-in;
    transition: 0.4s;
}

a:hover {
    color: #ff8800;
}

a:focus {
    border: 0;
    outline: 0;
    text-decoration: none;
}

input:focus {
    outline: #ff8800 1px !important;
}

.form-control:focus {
    border: 1px solid #ff8800;
    box-shadow: none;
}

.disabled {
    opacity: .6;
}

.caret-outer {
    padding: 5px 5px 5px 0;
    cursor: pointer;
}

.caret-outer .caret {
    color: #3E2622;
}

.caret-outer:hover .caret {
    color: #ff8800;
}

.word-break {
    word-break: break-all;
}

.word-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label.label-danger {
    color: white !important;
}

#header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 20;
}

@media (min-width: 768px) {
    .text-sm-right {
        text-align: right;
    }
}

@media (min-width: 992px) {
    #main-container {
        margin-top: 0;
    }

    #header {
        position: relative;
        width: initial;
    }

    #homepage-intro-row {
        margin-top: 0;
    }
}

/*************************************FLASHE******************************/

#session-messages .message {
    padding: 25px 30px 15px;
    background: white;
    margin-top: 25px;
}

#session-messages .message .header {
    display: none;
}

/*************************************RESPONSIVE SIDEBAR******************************/

#sidebar-bg {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: black;
    z-index: 19;
    display: none;
}

#sidebar-bg.toggled {
    display: block;
}

#main-sidebar {
    top: 71px;
    max-width: 300px;
    left: -300px;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: scroll;
    position: fixed;
    background: white;
    padding: 0;
    bottom: 0;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

#main-sidebar.toggled {
    left: 0;
}

#sidebar-toggle {
    float: left;
    margin-left: 15px;
}

@media (min-width: 768px) {
    #sidebar-toggle {
        display: initial;
    }
}

@media (min-width: 992px) {
    #sidebar-toggle {
        display: none;
    }

    #main-sidebar {
        max-width: initial;
        z-index: 1;
        float: left;
        position: relative;
        overflow: visible;
        padding: 0 15px;
        bottom: auto;
        top: auto;
        left: auto;
        background: transparent;
    }
}

/**************************************BUTTONS*****************************************/

.btn {
    padding: 15px 20px;
    border-radius: 0;
    text-align: center;
    transition-timing-function: ease-in;
    transition: 0.2s;
    text-transform: lowercase;
}

.btn:focus {
    border-color: transparent;
    outline: 0;
}

.btn:active {
    box-shadow: none;
}

.btn-brown {
    background: #3E2622;
    color: white;
}

.btn-brown:hover,
.btn-brown:focus {
    background: #ff8800;
    color: white;
}

.btn-orange {
    background: #ff8800;
    color: white;
}

.btn-orange:hover,
.btn-orange:focus {
    background: #3E2622;
    color: white;
}

.btn-facebook {
    background: #4267B2;
    color: white;
    text-transform: initial;
}

.btn-facebook:hover,
.btn-facebook:focus {
    color: white;
}

.btn-facebook__brand {
    font-weight: 600;
}

.btn-facebook__logo {
    vertical-align: bottom;
    margin-right: 10px;
}

.btn-ico-left {
    margin-right: 10px;
}

.btn-ico-right {
    margin-left: 10px;
}

/**************************************FORMS*****************************************/

textarea {
    resize: vertical;
}

fieldset {
    padding: 15px 0;
}

.ui-widget.ui-widget-content {
    border: 0;
}

.form-group {
    display: flex;
}

.form-group > div[class*=col] {
    padding-left: 0;
}

.form-control {
    border-radius: 0 !important;
    height: inherit;
    border: 1px solid #EDE9E7;
    outline: 0;
    box-shadow: none;
}

.form-horizontal .form-control {
    height: 100%;
}

.form-group.has-error {
    margin-bottom: 0;
}

form .help-block {
    color: #d9534f;
}

label.control-label {
    padding: 15px 15px !important;
    line-height: 1;
    background: #EDE9E7;
    text-align: center !important;
    white-space: nowrap;
}

.form-horizontal label.control-label {
    margin-left: 15px;
}

.vertical-form .form-group {
    display: block;
}

.vertical-form .control-label {
    padding: 0;
    background: transparent;
}

.collection-widget .btn-success {
    border: 0;
    background: #ff8800;
    color: white;
}

.collection-widget .btn-success:hover {
    background: #3E2622;
    color: white;
}

#price-total {
    background: white;
    border: 1px solid #EDE9E7;
    min-width: 100px;
    text-align: center;
}

.collection-rescue-add {
    margin-bottom: 15px;
}

.collection-actions {
    margin-right: 15px;
}

/***********************************SPINNER******************************************/

.ui-spinner {
    border: 0;
    border-radius: 0;
    height: initial !important;
    min-height: 40px;
    min-width: 120px;
}

.form-horizontal .ui-spinner {
    height: 100% !important;
}

.ui-spinner input.ui-spinner-input {
    height: 100%;
    margin: 0;
    border: 1px solid #dbcdc8;
    text-align: center;
}

.ui-spinner input[type="text"].ui-spinner-input {
    max-width: 50px;
    height: 100%;
    margin: 0 40px;
    border: 1px solid #dbcdc8;
    text-align: center;
}

.ui-spinner input[type="number"].ui-spinner-input {
    max-width: 100px;
    padding-left: 30px;
}

.ui-spinner .ui-spinner-button {
    position: absolute;
    height: 100%;
    width: 40px;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    background: #42211b;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

.ui-spinner .ui-spinner-button:hover,
.ui-spinner .ui-spinner-button:active,
.ui-spinner .ui-spinner-button:focus {
    background-color: #ff8800;
    border: 0;
}

.ui-spinner .ui-spinner-up {
    right: 0;
}

.ui-spinner .ui-spinner-down {
    left: 0;
}

.ui-spinner-button.ui-spinner-down {
    background-image: url('/assets/shop/images/ico_down.png') !important;
    background-position: center;
    background-repeat: no-repeat;
}

.ui-spinner-button.ui-spinner-up {
    background-image: url('/assets/shop/images/ico_up.png') !important;
    background-position: center;
    background-repeat: no-repeat;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
    opacity: .6;
}

/****************************CHECKBOX************************/

.form-group > div.col-sm-12 {
    padding-left: 15px;
    padding-right: 15px;
}

/*.checkbox {
    margin: 0;
}

.checkbox label {
    display: inline-block;
    margin: 5px 0;
    padding-left: 0;
}

.checkbox input[type=checkbox] {
    position: relative;
    margin-left: 0;
}

input[type=checkbox] {
    border: 1px solid #EDE9E7;
    padding: 10px;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 40px;
    height: 40px;
}

input[type=checkbox] {
    vertical-align: middle;
    margin: 0 10px 0 0;
    cursor: pointer;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

@media (min-width: 992px) {
    input[type=checkbox]:hover {
        background: url('/assets/shop/images/check-40x40.png') #ff8800;
        border: 1px solid #ff8800;
    }
}

input[type=checkbox]:checked {
    background: url('/assets/shop/images/check-40x40.png') #4E342E;
    border: 1px solid #4E342E;
}

.form-horizontal .checkbox {
    padding: 0;
}

.form-horizontal .checkbox label {
    margin: 0;
}*/

.checkbox {
    padding-left: 40px;
}

.checkbox input[type=checkbox] {
    margin-left: -40px;
    width: 40px;
    height: 40px;
    margin-top: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}

.checkbox label {
    height: 40px;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

.checkbox input[type="checkbox"]:checked + label::after {
    content: "";
    width: 40px;
    height: 40px;
    background: url('/assets/shop/images/check-40x40.png') #ff8800;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #555555;
}

.checkbox label::before {
    cursor: pointer;
    content: "";
    display: inline-block;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    border: 1px solid #EDE9E7;
    background-color: #fff;
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    padding-top: 0;
}

/**********************************SELECT****************************/

.select2-dropdown {
    border-radius: 0;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    float: left;
    margin-right: 5px;
}

#content .select2-container {
    height: 100%;
    max-width: 100%;
    width: 100%;
    border: 0;
}

#content .select2-container .select2-selection--single,
.modal .select2-container .select2-selection--single {
    height: 100%;
    min-height: 38px;
    border-radius: 0;
    outline: 0;
    border: 1px solid #EDE9E7;
}

.modal #sylius-product-adding-to-cart {
    max-width: 320px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 15px 54px 13px 11px;
    line-height: 1;
}

.select2-container .select2-selection--single .select2-selection__arrow {
    top: 0;
    right: 0;
    outline: 0;
    width: 40px;
    height: 100%;
    background: #3E2622;
}

.select2-container .select2-selection--single .select2-selection__arrow:hover {
    background: #ff8800;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: #b5b5b6;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
    border-color: white transparent transparent transparent;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent white transparent;
}

.select2-container.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent white transparent;
}

.select2-container .select2-results__option--highlighted[aria-selected] {
    background-color: #d4d4d4;
    color: #3E2622;
}

.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ececec;
}

.select2-container--open .select2-selection {
    border: 1px solid #aaa !important;
}

/************************************* RADIO **************************************************************/

label .radio-white {
    border: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
    margin: 4px 6px 4px 0;
    display: inline-block;
}

label:hover .radio-white {
    border: 5px solid #ff8800;
}

label.checked .radio-white {
    border: 5px solid white;
    background-color: #ff8800;
}

/****************************************COMMENTS******************************************************/

#comments-widget {
    margin-top: 30px;
    color: #42211b;
}

#comments-widget a {
    color: #42211b;
    transition-timing-function: ease-in;
    transition: 0.4s;
}

#comments-widget a:hover {
    color: #FF8F00;
}

#comments-widget .comment {
    padding: 30px 15px;
    background: white;
    margin-bottom: 2px;
}

#comments-widget .comment-header {
    margin-bottom: 10px;
}

#comments-widget .widget-content {
    padding: 0 15px;
    background: transparent;
}

#comments-widget .comment-author {
    font-weight: 600;
}

#comments-widget .comment-time {
    float: right;
    font-size: 12px;
}

#comments-widget .comment-message {
    font-size: 16px;
}

.widget-wide .widget-header h3 {
    text-align: center;
    font-weight: 300;
}

/****************************************COMMENT FORM************************************************/

#comment-form-widget .form-group {
    padding: 0;
}

#comment-form-widget .form-group .checkbox, #comment-form-widget .form-group .checkbox label {
    margin: 0;
}

#comment-form-widget textarea {
    border: 1px solid #efe9e9;
    resize: none;
    height: 150px;
}

#comment-form-widget .form-actions {
    text-align: right;
}

#comment-form-widget .not-logged-info {
    text-align: center;
}

#comment-form-widget a {
    color: #42211b;
    transition-timing-function: ease-in;
    transition: 0.4s;
}

#comment-form-widget a:hover {
    color: #FF8F00;
}

#comment-form-widget .checkbox label {
    padding-left: 15px;
}

/*******************************WIDGET*******************************************/

.widget ul {
    padding: 0;
    margin: 0;
}

/******************************WIDGET SIMPLE LIST*************************************/

.widget ul.simple-list {
    padding: 0;
    list-style: none;
}

.widget ul.simple-list a {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #42211b;
    transition-timing-function: ease-in;
    transition: 0.4s;
}

.widget ul.simple-list a:hover {
    color: #E86800;
}

.glyphicon.fast-right-spinner {
    -webkit-animation: glyphicon-spin-r 1s infinite linear;
    animation: glyphicon-spin-r 1s infinite linear;
}

.glyphicon.normal-right-spinner {
    -webkit-animation: glyphicon-spin-r 2s infinite linear;
    animation: glyphicon-spin-r 2s infinite linear;
}

.glyphicon.slow-right-spinner {
    -webkit-animation: glyphicon-spin-r 3s infinite linear;
    animation: glyphicon-spin-r 3s infinite linear;
}

.glyphicon.fast-left-spinner {
    -webkit-animation: glyphicon-spin-l 1s infinite linear;
    animation: glyphicon-spin-l 1s infinite linear;
}

.glyphicon.normal-left-spinner {
    -webkit-animation: glyphicon-spin-l 2s infinite linear;
    animation: glyphicon-spin-l 2s infinite linear;
}

.glyphicon.slow-left-spinner {
    -webkit-animation: glyphicon-spin-l 3s infinite linear;
    animation: glyphicon-spin-l 3s infinite linear;
}

@-webkit-keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes glyphicon-spin-r {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@-webkit-keyframes glyphicon-spin-l {
    0% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes glyphicon-spin-l {
    0% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

/**************************************MAIN MENU****************************************/

#main-menu li span {
    text-transform: lowercase;
}

#sub-menu li span {
    text-transform: lowercase;
}

/***************************************TABLE********************************************/

.table td {
    text-align: center;
}

.table td:first-of-type {
    text-align: left;
}

.table-brown > thead > tr > th,
.table-brown > tbody > tr > th,
.table-brown > tfoot > tr > th {
    text-align: center;
    background: #42211b;
    color: white;
    font-weight: 400;
    border: 1px solid #42211b !important;
}

.table-brown > thead > tr > th > a,
.table-brown > tbody > tr > th > a,
.table-brown > tfoot > tr > th > a {
    color: white;
}

.table-brown th:nth-of-type(even) {
    background: #57302a;
}

.table-bordered {
    border: 1px solid #efe9e9;
}

.table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > thead > tr > th {
    border: 1px solid #efe9e9;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
    padding: 15px;
}

/********************************OTHERS********************************************/

#add-to-cart .action-icon {
    margin-right: 10px;
}

select.form-control {
    width: inherit;
    padding-right: 40px;
}

/**************************************PAGINATION*********************************************/

.pagination-row {
    display: flex;
    margin-bottom: 10px;
    color: #8B6E62;
    font-size: 14px;
}

.pagination-row > div {
    flex-grow: 1;
}

.pagination-row label {
    display: none;
    margin: 0;
}

.pagination-row a,
.pagination-row span {
    height: 50px;
}

.pagination-row > div:first-child {
    padding: 0 8px 0 15px;
}

.pagination-row > div:last-child {
    padding: 0 15px 0 8px;
}

.pagination-row a:hover {
    opacity: 0.7;
}

.pagination-row #first a,
.pagination-row #first span {
    background: url("/assets/shop/images/first.png") no-repeat white center center;
}

.pagination-row #previous a,
.pagination-row #previous span {
    background: url("/assets/shop/images/previous.png") no-repeat white center center;
}

.pagination-row #next a,
.pagination-row #next span {
    background: url("/assets/shop/images/next.png") no-repeat white center center;
}

.pagination-row #last a,
.pagination-row #last span {
    background: url("/assets/shop/images/last.png") no-repeat white center center;
}

.pagination-row a,
.pagination-row span,
.pagination-row p,
.pagination-row > div > div {
    display: inline-block;
    margin-bottom: 0;
    padding: 15px 10px;
    width: 100%;
    text-align: center;
    background: white;
}

select#page {
    border: none;
    cursor: pointer;
}

@media (min-width: 768px) {
    .pagination-row {
        display: block;
    }

    .pagination-row > div {
        padding: 0 8px;
    }

    .pagination-row label {
        display: inherit;
    }
}

/***************************************FOOTER************************************************/

#footer,
#footer ul li a {
    margin-top: 30px;
    background: #3E2622;
    color: #EDE9E7;
    font-size: 12px;
    font-weight: 300;
    padding: 20px 0;
}

#footer {
    margin: 0;
}

#footer .footer-element {
    line-height: 50px;
}

#footer ul {
    margin: 0;
}

#footer .footer-left,
#footer .footer-right {
    text-align: center;
}

@media (min-width: 768px) {
    #footer .footer-left {
        text-align: left;
    }

    #footer .footer-right {
        text-align: right;
    }

    #footer p.footer-element {
        font-size: 10px;
    }

    .footer-menu > li {
        padding-left: 0;
    }

    .footer-menu > li:last-child {
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .footer-menu > li {
        padding-left: 5px;
    }

    .footer-menu > li:last-child {
        padding-right: 5px;
    }
}

@media (min-width: 1200px) {
    #footer p.footer-element {
        font-size: 14px;
    }
}
