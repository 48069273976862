label {
    font-weight: 300;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

.center {
    text-align: center;
}

.table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

/****************************************HEADER************************************************/

#header {
    padding: 0;
    max-height: 100%;
    overflow-y: auto;
    min-width: 320px;
}

.navbar-brand {
    margin: 10px auto;
    padding: 0;
    float: none;
    display: block;
    text-align: center;
}

.navbar-brand > img {
    margin: 0 auto;
}

.navbar-brand span {
    display: none;
}

.navbar-default .navbar-toggle {
    margin: 13px 15px 0 0;
    z-index: 999;
    background-color: transparent;
    border-radius: 0;
    border: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: inherit;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #efeae8;
    border-radius: 0;
    height: 6px;
    width: 30px;
}

.navbar-default .navbar-toggle .icon-bar + .icon-bar {
    margin-top: 5px;
}

.navbar-default.open #menu-toggle,
#sidebar-toggle.toggled {
    background: #FF8F00;
}

#main-menu,
#sub-menu {
    display: none;
    margin: 0;
}

.navbar-default.open #main-menu,
.navbar-default.open #sub-menu {
    display: block;
}

.navbar-default {
    background-color: #3D2621;
    border: none;
    border-radius: 0;
    margin-bottom: 0;
}

#main-menu > li > a {
    border-top: 1px solid #4f332e;
}

#sub-menu > li > a {
    border-bottom: 1px solid #3D2621;
}

.navbar-default .navbar-nav > li > a {
    font-family: Ubuntu, sans-serif;
    font-weight: 300;
    font-size: 14px;
    color: #EDE9E7;
    transition-timing-function: ease-in;
    transition: 0.4s;
    margin: 0;
    padding: 10px;
    z-index: 999;
}

.navbar-default .navbar-nav > li > a > img {
    display: inline-block;
    margin-right: 15px;
}

.navbar-default .navbar-nav > li > a:focus {
    color: #EDE9E7;
}

.navbar-default .navbar-nav > li > a:hover {
    color: #FF8F00;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover {
    color: #FF8F00;
    background: transparent;
}

.nav > li > a {
    padding: 0 15px 0 0;
    margin: 15px 0;
}

.nav > li > a > img {
    width: 40px;
}

.navbar-nav {
    float: right;
}

/*header dropdown*/

/*dla hovera*/
/*.navbar-nav .dropdown:hover > form {*/
/*display: block;*/
/*}*/

.navbar-nav .dropdown-menu {
    overflow: hidden;
    background-color: #4e342e;
    border: none;
    border-radius: 0;
    margin-top: 0;
    padding: 10px 0;
    right: 0;
    left: auto;
}

#main-menu {
    width: 100%;
}

#search-box {
    display: inline-block;
    position: relative;
    background: none;
    box-shadow: none;
}

.search-box__select label {
    margin-right: 20px;
}

#login-box {
    width: 700px;
    right: -125px;
}

.navbar-nav .dropdown-menu label {
    color: #EDE9E7;
    white-space: nowrap;
    vertical-align: middle;
}

#login-box.dropdown-menu label {
    margin-left: 20px;
    margin-right: 8px;
}

.navbar-nav .dropdown-menu input[type=text],
.navbar-nav .dropdown-menu input[type=password],
.navbar-nav .dropdown-menu input[type=submit],
.navbar-nav .dropdown-menu input[type=button],
.navbar-nav .dropdown-menu a.dropdown-menu-button {
    height: 46px;
    vertical-align: middle;
    border: 5px solid #fff;
    display: inline-block;
}

.navbar-nav .dropdown-menu a.dropdown-menu-button {
    line-height: 42px;
    text-align: center;
    text-decoration: none;
}

.navbar-nav .dropdown-menu input[type=text],
.navbar-nav .dropdown-menu input[type=password] {
    width: 200px;
    margin: 5px 0 5px 15px;
    padding: 0 10px;
    color: #3E2622;
}

.navbar-nav .dropdown-menu input[type=submit] {
    background: url("/assets/shop/images/arrow-right-white.png") no-repeat center;
    color: transparent !important;
}

.navbar-nav .dropdown-menu input[type=submit],
.navbar-nav .dropdown-menu input[type=button],
.navbar-nav .dropdown-menu a.dropdown-menu-button {
    width: 46px;
    background-color: transparent;
    color: #fff;
    font-size: 2em;
    font-weight: 700;
    margin: 7px;
}

.navbar-nav .dropdown-menu input[type=submit]:hover,
.navbar-nav .dropdown-menu input[type=button]:hover,
.navbar-nav .dropdown-menu a.dropdown-menu-button:hover {
    background-color: #FF8F00;
}

@media (min-width: 768px) {
    #header {
        overflow-y: visible;
    }

    #main-menu {
        width: auto;
    }

    #main-menu,
    #sub-menu {
        display: block;
    }

    #search-box {
        width: 450px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        background-color: #4e342e;
        position: absolute;
        display: none;
    }

    .open > #search-box {
        display: block;
    }

    #search-box.dropdown-menu input[type=text],
    #search-box.dropdown-menu input[type=password] {
        width: 250px;
        margin: 5px 7px 5px 5px;
    }

    .navbar-brand {
        margin: 20px -10px 20px 5px;
    }

    .navbar-brand > img {
        margin: 0;
    }

    .navbar-default .navbar-nav > li > a {
        padding: 0 15px 0 0;
        margin: 15px 0;
    }

    .navbar-default .navbar-nav > li > a > img {
        margin-right: 0;
    }

    #main-menu > li > a,
    #sub-menu > li > a {
        border: none;
    }

    #navbar-sub .navbar-right,
    .navbar-extended #navbar-main .nav {
        margin-right: 70px;
    }

    #navbar-sub .navbar-nav > li > a {
        margin: 5px 0;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    #main-menu {
        margin-right: -50px;
    }

    .navbar-extended #navbar-main .nav {
        margin-right: 30px;
    }

    #navbar-main .navbar-nav > li > a {
        text-align: center;
        padding: 0 5px;
    }

    #navbar-main .navbar-nav > li > a > img {
        display: block;
        margin: auto;
    }
}

@media (min-width: 992px) {
    .navbar-brand {
        margin: 15px -35px 15px 5px;
    }

    .nav > li > a > img {
        width: auto;
    }

    #main-menu > li > a {
        line-height: inherit;
        text-align: left;
        padding: 0 5px 0 0;
    }
}

@media (min-width: 1200px) {
    .navbar-default .navbar-nav > li > a {
        padding: 0 15px 0 0;
    }
}

/*****************************************SUBHEADER************************************************/

#navbar-sub {
    background-color: #4F332E;
}

#navbar-sub .container {
    position: relative;
}

#navbar-sub .nav {
    width: 100%;
}

#navbar-sub .navbar-nav > li > a {
    margin: 0;
    font-size: 12px;
}

#navbar-sub .navbar-nav > li > a > img {
    width: 40px;
    height: 40px;
}

#navbar-sub .dropdown-menu {
    min-width: 100px;
    padding: 20px 0;
    right: -55px;
    background-color: #3D2621;
}

#navbar-sub .dropdown-menu > li > a {
    color: #EDE9E7;
    padding: 3px 30px;
    font-weight: 300;
}

#navbar-sub .dropdown-menu > li > a:hover {
    color: #ff8800;
    background-color: transparent;
}

.navbar-extended #search-box {
    right: 0;
    left: auto;
}

#navbar-sub .navbar-right a {
    padding: 0;
}

#navbar-sub .navbar-userpic {
    position: absolute;
    bottom: 20px;
    right: 10px;
    border: 4px solid #EDE9E7;
    background-color: #EDE9E7;
    border-radius: 100%;
    overflow: hidden;
    width: 80px;
    height: 80px;
}

/*****************************************INTRO************************************************/

#carousel {
    margin-top: 30px;
}

.carousel-control {
    width: 5%;
    background: none !important;
}

/***************************NOT LOGGED INTRO***************************************/

#homepage-intro-row {
    background: url('/assets/shop/images/intro-homepage.jpg') center center;
    background-size: cover;
}

#intro-box {
    padding: 40px 0 80px 0;
}

p#intro-header {
    font-size: 45px;
    color: white;
    font-weight: 300;
}

font#head-bold {
    font-weight: 800;
}

p#sub-header {
    font-size: 25px;
    color: white;
    font-weight: 400;
}

#intro-row-2 {
    position: relative;
    margin-top: -120px;
    width: 100%;
    height: 120px;
    line-height: 50px;
}

#join-box {
    padding: 15px 0;
    font-size: 28px;
    color: white;
    font-weight: 300;
    text-align: center;
    background: rgba(62, 38, 34, 0.85);
}

@media (min-width: 768px) {
    #join-box {
        padding: 30px 0;
    }
}

#join-box > div {
    text-align: center;
}

#join-box label {
    width: 100%;
}

@media (min-width: 768px) {
    #join-box label {
        width: inherit;
        margin-right: 20px;
    }
}

#join-box input {
    width: 240px;
    height: 50px;
    line-height: 40px;
    border: 4px solid white;
    background: none;
    font-size: 1em;
    padding-left: 15px;
}

#join-box input[type=submit] {
    background: url("/assets/shop/images/submit-arrow.png") top -4px left -4px;
    width: 50px;
    height: 50px;
    margin-left: -10px;
}

#join-box input[type=submit]:hover {
    background-color: rgba(255, 144, 0, 0.85);
}

@media (min-width: 992px) {
    #intro-box {
        padding: 90px 0 180px 0;
    }

    p#intro-header {
        font-size: 48px;
    }

    p#sub-header {
        font-size: 32px;
    }

    #intro-row-2 {
        margin-top: -100px;
        height: 100px;
    }
}

/*video*/
#intro-video {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
}

/**************************************FIXED BOXES*****************************************/

#social-box {
    display: none;
    z-index: 99;
    position: fixed;
    left: 0;
    top: 160px;
}

#suggest-box {
    display: none;
    z-index: 99;
    position: fixed;
    right: -170px;
    width: 230px;
    top: 160px;
    background: #4E342E;
    transition-timing-function: ease-in;
    transition: 0.1s;
}

#suggest-box:hover {
    right: 0;
    transition-timing-function: ease-in;
    transition: 0.3s;
}

#suggest-box span {
    color: #EDE9E7;
    font-size: 11px;
    font-weight: 300;
}

#suggest-box span#suggest-text {
    padding: 11px 0 0 77px;
    display: block;
}

#suggest-box a:hover {
    text-decoration: none;
}

#suggest-box img {
    float: left;
}

#watch {
    display: inline-block;
    padding: 0 0 0 25px;
    margin-top: 20px;
    border: 4px solid white;
    color: white;
    transition-timing-function: ease-in;
    transition: 0.3s;
}

#watch .arrow-btn {
    margin-left: 25px;
    padding: 0;
    border-left: 4px solid white;
}

a#watch {
    font-size: 20px;
    font-weight: 300;
}

a#watch:hover {
    text-decoration: none;
    background: rgba(255, 144, 0, 0.85);
}

.arrow-btn {
    display: inline-block;
    margin-left: 10px;
    padding: 10px 16px;
    border-left: 2px solid white;
}

@media (min-width: 768px) {
    #social-box {
        display: block;
    }

    #suggest-box {
        display: block;
    }
}

/***************************************SIDEBAR************************************************/

.widget {
    margin-bottom: 18px;
}

.widget-header {
    background: #3E2622;
    color: white;
}

.widget-header > * {
    padding: 25px 25px 25px 30px;
    font-weight: 700;
    text-align: left;
}

.widget-header h3 {
    font-size: 16px;
    margin: 0;
    text-align: center;
    text-transform: lowercase;
}

.widget-header h3.text-center {
    text-align: center;
}

.widget-content {
    padding: 15px;
    background: white;
}

.widget-content h4 {
    margin-top: 0;
}

form .form-header {
    margin-bottom: 15px;
    background: #653e32;
    padding: 15px;
    color: white;
    font-weight: 400;
}

form .form-header > * {
    margin: 0;
}

@media (min-width: 768px) {
    .widget-content {
        padding: 30px;
    }
}

/**************************************CONTENT************************************************/

#content {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 0;
}

#breadcrumb, #breadcrumb ul li a {
    font-size: 12px;
    color: #785448;
}

#breadcrumb p {
    padding: 0;
    margin-bottom: 0;
}

#breadcrumb .widget-content {
    padding: 14px 16px;
}

.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: white;
    border-radius: 0;
}

#categories ul ul {
    padding: 5px 10px 5px 15px;
}

#categories ul li {
    display: block;
    padding: 3px;
}

#categories ul li > a {
    color: #8B6E62;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

#categories ul li.bold > a {
    font-weight: 700;
}

#categories ul li > a:hover, #categories ul li a:focus {
    color: #ff8800;
    text-decoration: none;
}

#price-filter label,
#name-filter label {
    font-size: 14px;
    color: #3E2622;
    font-weight: 400;
    margin-bottom: 14px;
}

#price-range-slider {
    background: #EDE9E7;
    border-radius: 0;
    border: none;
    height: 4px;
    margin: 20px 10px;
}

#price-range-slider .ui-state-default {
    background: #ff8800;
    border-radius: 0;
    border: none;
    cursor: pointer;
    top: -6px;
}

#name-filter input[type=text],
#price-filter input[type=text] {
    border: 1px solid #EDE9E7;
    padding: 10px;
    text-align: center;
    color: #8B6E62;
    font-size: 14px;
    width: 100%;
}

#price-filter .filter-number-range {
    text-align: center;
}

#price-filter .filter-number-range input[type=text] {
    width: 48%;
}

.submit-filter input {
    width: 100%;
}

div.filter {
    margin-bottom: 30px;
}

div.filter:last-child {
    margin-bottom: 0;
}

/*div.filter.checklist label {
    display: inline-block;
    margin: 5px 0;
}*/

.no-results {
    margin: 0 15px 30px;
}

/****************************SORT & TABS***************************************/

#sort {
    padding: 20px;
    color: #785448;
    background: #fff;
    margin: 0 15px 15px;
    display: flex;
    align-items: baseline;
}

@media (min-width: 768px) {
    #sort {
        font-size: 12px;
    }
}

#sort .select2-container {
    flex-grow: 1;
    margin-left: 3px;
}

div#sort .select2-container .select2-selection--single .select2-selection__rendered {
    padding: 5px 0 5px 6px;
}

div#sort .select2-container .select2-selection--single {
    min-height: inherit;
}

div#sort .select2-container .select2-selection--single .select2-selection__arrow {
    width: 24px;
}

.nav-tabs {
    border: none;
    margin-bottom: 0;
}

#tabs {
    clear: both;
}

#tabs .nav-tabs > li {
    margin-bottom: 0;
}

#tabs .nav-tabs > li > a {
    border-radius: 0;
    border: none;
    margin: 0;
    padding: 18px 0 19px 0;
    font-size: 20px;
    font-weight: 400;
    color: #EDE9E7;
}

#tabs li.has-tab-info:hover ~ li.tab-info {
    visibility: visible;
    bottom: 0;
    transition-timing-function: ease-in;
    transition: 0.3s;
}

#tabs .nav-tabs > li.tab-info {
    visibility: hidden;
    bottom: -93px;
    z-index: -999;
    background: #4E342E;
    font-size: 12px;
    color: #EDE9E7;
    padding: 11px 18px;
    text-align: right;
    display: none;
}

#tabs .nav-tabs > li:not(.active) > a {
    background-color: #3E2622;
}

#tabs .nav-tabs > li:not(.active) > a:hover {
    background-color: #ff8800;
    transition-timing-function: ease-in;
    transition: 0.2s;
}

#tabs .nav-tabs > li img {
    padding: 0;
    display: block;
    margin: 0 auto;
}

#tabs .nav-tabs > li.active > a {
    font-size: 20px;
    font-weight: 400;
    color: #ff8800;
}

#tabs ul li {
    width: 33%;
    text-align: center;
    background: #3E2622;
    color: white;
}

#tabs .nav-tabs {
    display: flex;
}

#tabs .nav-tabs li {
    display: flex;
    flex: 1;
}

#tabs .nav-tabs > li > a {
    margin-right: 0;
}

#tabs .nav-tabs li a {
    flex: 1;
}

@media (min-width: 480px) {
    #tabs .nav-tabs > li img {
        padding-right: 10px;
        display: inline-block;
    }
}

@media (min-width: 768px) {
    div.top-row {
        margin-left: 0;
    }

    #sort {
        margin: 0;
    }

    #tabs {
        clear: none;
    }

    .nav-tabs {
        padding-left: 5px;
    }

    #tabs .nav-tabs > li.tab-info {
        display: flex;
    }
}

/***************************************MAIN CONTENT*******************************************/

#main .content-row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 15px;
    padding: 30px 15px 0;
    background: white;
}

.product-image {
    position: relative;
    border: 1px solid #EDE9E7;
    border-bottom-width: 2px;
    margin-bottom: 20px;
}

.product {
    position: relative;
    margin-bottom: 28px;
}

.product:hover .product-hover {
    visibility: visible;
    opacity: 1;
}

.product-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 9;
    opacity: 0;
    transition: opacity .2s ease-out;
}

.product-hover a {
    color: white;
    text-decoration: none;
}

.product-hover p {
    font-size: 14px;
    font-weight: 300;
}

.product-desc h5 {
    color: #3E2622;
    font-size: 14px;
    line-height: 14px;
    height: 28px;
    font-weight: 500;
    overflow: hidden;
}

.product-desc p.desc-text {
    color: #3E2622;
    font-size: 10px;
    line-height: 14px;
    height: 28px;
    font-weight: 300;
    overflow: hidden;
}

p.price {
    color: #3E2622;
    font-size: 20px;
    max-height: 28px;
}

p.price .amount {
    font-weight: 400;
}

p.price .promo {
    color: #ff9000;
}

p.price .promo del {
    color: #dbcdc8;
}

/*HOVER*/
.show-product {
    display: table;
    width: 100%;
    height: 100%;
}

.show-product .table-cell {
    display: none;
}

@media (min-width: 992px) {
    .show-product {
        height: 50%;
        background: rgba(255, 143, 0, 0.85);
        transition-timing-function: ease-in;
        transition: 0.2s;
    }

    .product-hover:hover .show-product {
        height: 40%;
    }

    .product-hover:hover .show-product:hover {
        height: 60%;
        transition-timing-function: ease-in;
        transition: 0.2s;
    }

    .show-product .table-cell {
        display: table-cell;
    }
}

.to-basket {
    display: none;
}

@media (min-width: 992px) {
    .to-basket {
        display: table;
        width: 100%;
        height: 50%;
        background: #3E2622;
        transition-timing-function: ease-in;
        transition: 0.2s;
    }

    .to-basket p {
        color: #FF8F00;
    }

    .product-hover:hover .to-basket {
        height: 40%;
    }

    .product-hover:hover .to-basket:hover {
        height: 60%;
        transition-timing-function: ease-in;
        transition: 0.2s;
    }
}

/*HOVER VERTICAL*/
.product-hover.vertical .show-product {
    float: left;
    width: 50%;
    height: 100%;
}

.product-hover.vertical:hover .show-product {
    width: 40%;
    height: 100%;
}

.product-hover.vertical:hover .show-product:hover {
    width: 60%;
}

.product-hover.vertical .to-basket {
    float: left;
    width: 50%;
    height: 100%;
}

.product-hover.vertical:hover .to-basket {
    width: 40%;
    height: 100%;
}

.product-hover.vertical:hover .to-basket:hover {
    width: 60%;
}

/**************************************PROFILE************************************************/

.profile-item,
.content-element {
    padding: 15px;
    background: white;
}

.collection-list {
    margin: 0;
}

.profile-sidebar {
    padding: 10px;
    background: white;
}

.badge {
    background-color: #3E2622;
}

/**************************************CART***********************************************/

.cart-empty-message {
    padding: 25px 30px;
    background: white;
    margin-top: 25px;
}

.cart-widget {
    margin: 20px 0 10px 0;
    float: none;
}

.cart-widget > .row {
    margin-bottom: 20px;
}

#wallet-slider {
    background: #EDE9E7;
    height: .8em;
    border: 0;
    border-radius: 0;
    margin-bottom: 30px;
}

#wallet-slider > .ui-slider-handle {
    background: #ff8800;
    width: 0.7em;
    height: 1.9em;
    top: -.6em;
    margin-left: -.3em;
    border: 0;
    border-radius: 0;
}

#wallet-slider:hover > .ui-slider-handle {
    background: #3E2622;
}

#sylius-cart-items th,
#sylius-cart-items td {
    text-align: center;
}

#sylius-cart-items td:first-child {
    text-align: left;
}

#sylius-cart-items .btn-remove {
    padding: 9px 12px;
}

.widget-cart-bar {
    margin-bottom: 30px;
}

.widget-cart-bar h3 {
    margin: 5px 0 0 0;
    font-size: 20px;
}

.widget-cart-bar p {
    margin: 0;
}

#sylius-cart-items > tbody > tr > td {
    vertical-align: middle;
}

#sylius-cart-items .option-list.list-inline {
    margin: -5px 0;
}

#sylius-cart-items .option-list.list-inline li {
    padding-left: 0;
    font-size: 12px;
    border-right: 1px solid #c5c5c5;
}

#sylius-cart-items .option-list.list-inline li:last-of-type {
    border-right: none;
}

#sylius-cart-items > thead {
    text-transform: lowercase;
}

#sylius-cart-items > tbody > tr > td:first-of-type > div.item-thumbnail {
    float: left;
    margin-bottom: 10px;
    max-width: 64px;
}

@media (min-width: 768px) {
    #sylius-cart-items > tbody > tr > td:first-of-type > div.item-thumbnail {
        margin-bottom: 0;
    }
}

.continue-shopping {
    float: left;
}

@media (min-width: 992px) {
    .continue-shopping {
        float: none;
        margin-bottom: 0;
        margin-right: 10px;
    }
}

#sylius-cart-clear {
    margin-bottom: 10px;
}

#sylius-cart-clear,
#sylius-cart-clear button,
.continue-shopping {
    width: 100%;
}

@media (min-width: 355px) {
    #sylius-cart-clear {
        margin-bottom: 0;
    }

    #sylius-cart-clear button,
    #sylius-cart-clear,
    .continue-shopping {
        width: auto;
    }
}

#sylius-cart-items > tbody > tr > td:first-of-type > div:first-of-type img {
    border: 1px solid #efe9e9;
    margin: -7px 14px -7px -7px;
}

#sylius-cart-items > tbody > tr > td:first-of-type > div:first-of-type a {
    font-weight: 400;
}

#sylius-cart-items > tbody > tr > td:first-of-type > div.remove-item {
    float: right;
}

#sylius-cart-items div.product-name {
    clear: both;
}

@media (min-width: 768px) {
    #sylius-cart-items div.product-name {
        clear: none;
    }
}

#sylius-cart-items .ui-spinner.ui-corner-all {
    border-radius: 0;
    border: none;
}

#sylius-cart-items input[type="number"] {
    height: 40px;
    border: 1px solid #c5c5c5;
}

#sylius-save {
    width: 44px;
    height: 44px;
    background: #ff8800 url("/assets/shop/images/arrow-right-white.png") no-repeat center;
    color: transparent !important;
}

label.wallet-max {
    background: #ff8800;
    color: white;
}

.table-summary > tbody > tr > th {
    width: 60%;
    background: #efe9e9;
    text-transform: lowercase;
    font-weight: 300;
    padding-left: 25px;
}

.table-summary > tbody > tr:nth-of-type(even) > th {
    background: #e8e1df;
}

.table-summary > tbody > tr > td {
    text-align: right;
    padding-right: 25px;
}

.table-grand-total {
    margin-bottom: 0;
}

.table-grand-total > tbody > tr:nth-of-type(odd) > th {
    background: #3E2622;
    color: white;
}

.widget-cart-bar .row > div:first-of-type {
    margin-bottom: 10px;
}

@media (min-width: 992px) {
    .widget-cart-bar .row > div:first-of-type {
        margin-bottom: 0;
    }
}

/**************************************MARKET************************************************/

#recipe-search form .form-group {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
}

#recipe-search .select2 {
    width: 100% !important;
}

#recipe-search .filter-number-range {
    text-align: center;
}

#recipe-search .filter-number-range > input,
#recipe-search .filter-date-range > input {
    display: inline-block;
    width: 48%;
}

#recipe-search .filter-date-range > input {
    font-size: 12px;
    padding: 6px 6px;
}

@media (min-width: 768px) {
    #recipe-search form .form-group {
        margin-bottom: 15px;
    }
}

/*************************************RECEPTURY************************************************/

.recipe-elements {
    list-style: none;
}

.recipe-elements li {
    background: #FF8929;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 10px;
    color: white;
}

.recipe-elements li a {
    color: white;
}

#recipe-ingredients .delete-element {
    background: #E86800;
    border-radius: 10px;
    padding: 2px 5px;
    font-size: 12px;
    cursor: pointer;
    margin-left: 5px;
}

.bdp-input {
    display: inline-block;
}

/***********************LOGIN**********************************************/

#login-widget .widget-header h3 {
    text-align: center;
}

.widget-wide > .widget-header > h3 {
    text-align: center;
    text-transform: lowercase;
    font-weight: 400;
    font-size: 20px;
}

/*********************PRODUKT****************************************/

.single-product-images {
    margin-bottom: 15px;
    text-align: center;
}

.single-product-images .gallery {
    margin: 5px -5px;
}

.single-product-images .gallery > * {
    padding: 5px;
}

.single-product > .product-data {
    margin-bottom: 15px;
}

.single-product .product-name {
    font-size: 24px;
    color: #42211b;
}

.single-product .product-price {
    font-size: 20px;
}

.single-product .product-details {
    margin: 30px 0;
}

#sylius-product-selecting-variant .form-group {
    padding-left: 0;
}

#sylius-product-attributes {
    width: initial;
}

.sylius-product-attribute-value {
    text-align: right;
}

#sylius-product-attributes tr:nth-of-type(even) .sylius-product-attribute-name {
    background: #57302a;
}

#add-to-cart[disabled] {
    opacity: 1;
    background: #d9534f;
}

@media (min-width: 768px) {
    .single-product-images {
        margin-bottom: 0;
    }
}

/****************************************MODAL*************************************/

.modal-content {
    border-radius: 0;
}

.modal-header {
    padding: 30px;
}

.modal-body {
    padding: 30px;
}

/****************************************CHECKOUT************************************/

#order-steps {
    border: 0;
    border-radius: 0;
    margin-bottom: 30px;
    text-transform: lowercase;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background: 0 0;
    box-shadow: none;
    line-height: 1.14285714em;
    width: 100%;
}

#order-steps .step {
    border-right: 1px solid #efe9e9;
    border-radius: 0;
    border-right: 0;
    background: #42211b;
    color: white;
    width: 25%;
    padding: 15px 10px;
    align-items: center;
    display: flex;
}

@media (min-width: 768px) {
    #order-steps .step {
        padding: 15px 30px;
    }
}

#order-steps .step .title {
    color: white;
}

#order-steps .step:nth-child(even) {
    background: #57302a;
}

#order-steps .step.disabled {
    background: white;
}

#order-steps .step:after {
    display: none;
}

#order-steps .disabled {
    opacity: 1;
}

#order-steps i {
    width: 100%;
    text-align: center;
    margin-right: 0;
}

@media (min-width: 768px) {
    #order-steps i {
        width: inherit;
        margin-right: 10px;
    }
}

#order-steps .disabled .title {
    color: #57302a;
}

#checkout-subtotal th {
    text-transform: lowercase;
}

#checkout-subtotal-widget .widget-content {
    padding: 15px;
}

#checkout-subtotal-widget .table {
    margin: 0;
}

.method {
    border: 1px solid #efe9e9;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
}

.method h5 {

}

.method > div {
    float: left;
    display: flex;
    align-content: center;
    align-items: center;
}

.method > .radio-field {
    flex-shrink: 0;
    text-align: center;
}

.shipments-container .method > .icon {
    background: url('/assets/shop/images/shipment-personal.png') center;
    flex-shrink: 0;
    width: 100px;
    min-height: 100px;
}

.shipments-container .method:nth-of-type(2) > .icon {
    background: url('/assets/shop/images/shipment-dpd.png') center;
}

.shipments-container .method:nth-of-type(3) > .icon {
    background: url('/assets/shop/images/shipment-postal.png') center;
}

.payment-container .method > .icon {
    background: url('/assets/shop/images/payment-cash.png') center;
    flex-shrink: 0;
    width: 100px;
    min-height: 100px;
}

.payment-container .method:nth-of-type(2) > .icon {
    background: url('/assets/shop/images/payment-wire.png') center;
}

.payment-container .method:nth-of-type(3) > .icon {
    background: url('/assets/shop/images/payment-przelewy24.png') center;
}

.method > .content {
    flex-grow: 1;
    padding: 20px 0 20px 20px;
}

@media (min-width: 768px) {
    .method > .content {
        padding: 10px 10px 0 20px;
    }
}

.method > .content h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.method > .extra {
    margin: 10px 20px 0 0;
    font-size: 16px;
    font-weight: bold;
}

.method > .extra > p {
    width: 100%;
    text-align: center;
}

.checkout-control-row {
    margin-top: 30px;
    clear: both;
}

#shipping-address-widget .select2 {
    width: 100% !important;
}

#sylius_checkout_complete_notes {
    width: 100%;
}

/*********************************CHECKOUT RADIO BTN********************************/

.method > .radio-field > input {
    margin: 0 0 0 20px;
    width: 17px;
    height: 17px;
}

@media (min-width: 768px) {
    .method > .radio-field > input {
        margin: 0 8px;
    }
}

/*********************************CHECKOUT COMPLETE********************************/

/************************************ACCOUNT***************************************/

#account-widget .sub-header,
#account-widget .user-information,
#account-widget .user-email {
    margin-bottom: 15px;
}

#account-widget .sub-header {
    text-align: center;
    font-weight: bold;
}

#account-widget .user-dashboard-btns > a {
    margin-right: 15px;
}

#verification-form {
    display: inline-block;
}

#account-widget .user-dashboard-btns {
    margin-top: 45px;
}

#account-widget .checkbox {
    margin-bottom: 15px;
}

/*********************************LIGHTBOX********************************/

.sl-wrapper {
    z-index: 10000;
}

/*********************************FORM ERRORS********************************/

.red.sylius-validation-error {
    display: block;
    width: 100%;
    background: #B0413E;
    padding: 25px;
    font-size: 100%;
    margin-bottom: 15px !important;
}
